import { render, staticRenderFns } from "./miniSetting.vue?vue&type=template&id=533c6556&scoped=true&"
import script from "./miniSetting.vue?vue&type=script&lang=ts&"
export * from "./miniSetting.vue?vue&type=script&lang=ts&"
import style0 from "./miniSetting.vue?vue&type=style&index=0&id=533c6556&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533c6556",
  null
  
)

export default component.exports